import { useEffect, useRef } from 'react';
import { Container, Nav, Navbar, Row } from 'react-bootstrap';
import Link from 'next/link';
import Image from 'next/image'
import { useSession, signIn, signOut } from 'next-auth/client';

const Header = () => {
  const headerContainer = useRef(null);
  const [session] = useSession();
  useEffect(() => {
   const handleScroll= () => {
     if(headerContainer) {
      if (window.pageYOffset > 100) {
        headerContainer.current?.classList.add("fixed-top")
      }else{
        headerContainer.current?.classList.remove("fixed-top");
      }
     }
     
    }
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header ref={headerContainer} className="header-area">
      <Container>
        <Row>
          <Navbar expand="lg" className="w-100">
            <Navbar.Brand><Link href="/"><a><img src="/logo.svg" className="w-75" alt="programming hero" height={35} width={270} /></a></Link></Navbar.Brand>
            <Navbar.Toggle className="custom-toggler navbar-toggler" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="mr-5">
              <Nav className="ml-auto text-white">
                  <Link href="/">
                    <Nav.Link className="px-4" as="a" href="/">
                        Home
                    </Nav.Link>
                  </Link>
                  <Link href="/">
                    <Nav.Link className="px-4" as="a" href="/">
                    Post
                    </Nav.Link>
                  </Link>
                  <Link href="/">
                    <Nav.Link className="px-4" as="a" href="/">
                    Success
                    </Nav.Link>
                  </Link>
                  <Link href="/forum">
                    <Nav.Link className="px-4" as="a" href="/forum">
                     Forum
                    </Nav.Link>
                  </Link>
                  <div>
                    {session ? (
                    <button className="btn text-white btn-primary" onClick={signOut}>Log out</button>
                    ) : (
                    <button 
                    className="btn text-white btn-primary" onClick={()=>signIn('google')}>
                    Log in</button>
                    )}
                 </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Container>
    </header>
  );
};

export default Header;