const options = {  year: 'numeric', month: 'short', day: 'numeric' };

export const getDate = d => {
    let date = new Date(d);
    return date.toLocaleDateString('en-US', options);
}


export const  shorten = (text,max) => {
    return text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ') : text
}
  

export const removeTags = (str, max) => {
    if ((str===null) || (str===''))
    return '';
    else
    str = str.toString();
    const updateStr = str.replace( /(<([^>]+)>)/ig, '');
    return shorten(updateStr, max)
}